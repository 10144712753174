@import '../variables';
@import '../base/macros';
@import '../utils/mediaQueries';

$navContainerPadding: clamp-it(2.5rem, 2.8rem);

.navigation {
  position: relative;
  padding: 0 $navContainerPadding;

  @include medium {
    display: flex;
  }

  .navBtn.navBtn--slide {
    top: 50%;
    transform: translateY(-50%);
    opacity: 60% !important;
    transition: opacity 0.25s ease-in;

    &:hover {
      opacity: 100% !important;
    }

    > svg {
      height: clamp-it(1.5rem, 2rem);
    }

    &.navBtn--prev {
      left: 0;
    }

    &.navBtn--next {
      right: 0;
    }
  }

  &__container {
    @extend %no-scrollbar;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2.362rem;
    flex-grow: 1;
    padding: clamp-it(0.6rem, 1rem) 0 clamp-it(0.6rem, 1rem);
    //padding: clamp-it(0.6rem, 1rem) clamp-it(1rem, 3.8rem) clamp-it(0.6rem, 1rem);
    overflow: auto;
    user-select: initial;
  }

  &__item {
    display: inline-flex;
    flex-direction: column;
    color: inherit;
    transition: box-shadow 0.25s ease-in-out, font-weight 0.25s ease-in-out, opacity 0.25s ease-in;
    font-weight: 500;
    white-space: nowrap;
    opacity: 1;

    &--outside {
      opacity: 0;
      pointer-events: none;
    }

    &--active,
    &:hover {
      text-decoration: none;
      box-shadow: inset 0 -2px 0 $black;
      font-weight: 700;
    }
    &::after {
      content: attr(data-text) / '';
      height: 0;
      visibility: hidden;
      overflow: hidden;
      font-weight: 700;
    }
  }

  &__cta {
    //display: flex;
    position: relative;
    align-items: center;
    padding: clamp-it(0.8rem, 1.2rem) clamp-it(0.8rem, 7.75rem);
    margin-top: clamp-it(0.5rem, 0.85rem);
    right: calc($navContainerPadding * -1);
    background: $secondary-accent-green;
    color: $primary-dark-green;
    text-align: center;
    white-space: nowrap;
    transition: all 0.3s ease;
    // TODO: hide until pdf feature is implemented
    display: none;

    @include medium {
      margin-top: 0;
      padding: clamp-it(0.8rem, 1.2rem);
    }

    &-link {
      display: inline-flex;
    }
  }
}
