@import '../variables';
@import '../utils/functions';
@import '../base/grid';
@import './expandedGallery';

$containerInnerSpacingTop: clamp-it(1rem, 3.25rem);
$containerInnerSpacingBottom: clamp-it(0.8rem, 1.75rem);
$buttonDiameter: clamp-it(1.5rem, 3.25rem);

.image-gallery {
  overflow-x: hidden;
  overflow-y: hidden;

  > h3 {
    @include fluid-fonts(1.5rem, 3rem, 150%, 140%);
    font-weight: 400;
    margin-bottom: clamp-it(3.3rem, 3.75rem);
  }

  > h2 {
    @include fluid-fonts(1.5rem, 3.5rem, 150%, 140%);
    font-weight: 500;
    margin-bottom: clamp-it(1.25rem, 4rem);
    text-align: center;
  }

  &__container {
    position: relative;
    width: fit-content;
    transition: transform 1s ease-in-out 0s;

    &__inner {
      display: flex;
      position: relative;
      padding: $containerInnerSpacingTop 0 $containerInnerSpacingBottom;

      .hover-icon__container {
        margin-right: clamp-it(1rem, 2rem);
      }
    }
  }

  &__label {
    @include fluid-fonts(0.685rem, 0.875rem, 110%, 110%);

    > div:last-child {
      margin-top: clamp-it(0.3rem, 0.5rem);
    }
  }

  &__image {
    position: relative;
    object-fit: cover;
    width: calc(100vw - 2 * $section-spacing-x);
    height: 100%;
    //max-height: calc(
    //  100dvh - $section-spacing-y * 2 - $containerInnerSpacingTop - $containerInnerSpacingBottom -
    //    2.6875rem - $buttonDiameter
    //);
    transition: opacity 0.4s ease-in-out, height 0.4s ease-in-out;
  }

  .slider {
    list-style-type: none;
    padding: 0;
  }

  .navBtn {
    margin-left: clamp-it(0.6rem, 1.5rem);
    margin-right: 1px; // circle gets cut by 1px if not set
    margin-bottom: 1px; // circle gets cut by 1px if not set
    padding: 0;
    position: relative;
    background: transparent;
    border: 0;
    @include medium {
      display: inline-block;
    }
  }

  .expanded-gallery {
    &__button {
      right: 2px;
    }

    .slider {
      height: initial;
    }

    .carousel {
      .thumbs-wrapper,
      .thumbs-wrapper ul.thumbs {
        height: clamp-it(4.75rem, 4.875rem);
        position: relative;

        .thumb > img {
          height: clamp-it(4.75rem, 4.875rem);
          object-fit: cover;
        }
      }

      .thumbs-wrapper > .control-arrow {
        transform: translateY(-50%) translateY(2px);
        margin-top: initial;
      }
    }
  }

  .carousel.carousel-slider {
    padding-top: min(75%, calc(100vh - $carouselMargin - 3rem));

    .slider-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .expanded-gallery .carousel .thumbs-wrapper ul.thumbs,
  .expanded-gallery .carousel .thumbs-wrapper {
    height: initial;
  }
}
