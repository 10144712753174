@import '../utils/functions';
@import '../base/macros';

$itemPaddings: clamp-it(0.5rem, 0.75rem);
$imageMarginBottom: clamp-it(3.5rem, 4.5rem);

.selection-items {
  padding: clamp-it(1.375rem, 3.438rem) clamp-it(0.875rem, 2.688rem);
  background-color: $white;

  &__container {
    width: 100%;
  }

  &__label {
    @include fluid-fonts(1.1rem, 1.3rem, 100%, 100%);
    font-weight: 500;
    position: relative;
    left: $itemPaddings;
  }

  &__scroll {
    @extend %no-scrollbar;
    overflow-x: auto;
    width: 100%;
  }

  &__inner {
    display: flex;
    flex-wrap: nowrap;

    &--centered {
      @include xmedium {
        justify-content: center;
      }
    }
  }

  &__title {
    @include fluid-fonts(1.125rem, 1.375rem, 150%, 170%);
    font-weight: 500;
    padding: 0 $itemPaddings;
    margin: auto;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: clamp-it(0.85rem, 1.85rem);

    &__btns {
      display: flex;
      margin: 0.125rem calc($itemPaddings) 0.125rem 0;

      svg {
        height: 1em;
        width: 1em;
        font-size: clamp-it(2.2rem, 3rem);
        cursor: pointer;

        &:last-child {
          margin-left: clamp-it(0.75rem, 1.5rem);
        }

        circle {
          fill: transparent;
          stroke: $primary;
          transition: fill 0.25s ease-in, stroke 0.25s ease-in;
        }

        path {
          stroke: $primary;
          transition: stroke 0.25s ease-in;
        }

        &:hover {
          circle {
            fill: $primary;
          }

          path {
            stroke: $white;
          }
        }
      }

      &--disabled {
        > svg {
          cursor: default;

          circle {
            stroke: $gray-400;
          }

          path {
            stroke: $gray-400;
          }

          &:hover {
            circle {
              fill: transparent;
            }

            path {
              stroke: $gray-400;
            }
          }
        }
      }
    }

    &--no-btns {
      justify-content: flex-start;
    }
  }
}

.selection-item {
  background-color: $primary-light-blue;
  display: flex;
  padding: clamp-it(1.25rem, 1.625rem);
  position: relative;
  width: 100%;
  height: 100%;

  &__outer {
    display: flex;
    padding: 0 $itemPaddings;
    flex-direction: column;
    flex: 1 0 100%;
    max-width: 100%;

    @include xmedium {
      flex: 1 0 calc((100 / 3) * 1%);
      max-width: calc((100 / 3) * 1%);
    }

    @include xxlarge {
      flex: 1 0 25%;
      max-width: 25%;
    }
  }

  &__image {
    z-index: 1;
    margin-bottom: calc($imageMarginBottom * -1);

    img {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
    }
  }

  &__inner {
    background-color: $white;
    padding: clamp-it(0.938rem, 2.188rem);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 2;
  }

  &__name {
    display: inline-block;
    font-weight: 500;
    line-height: 120%;
    border-bottom: 1px solid $primary;
    margin-bottom: clamp-it(0.8rem, 1rem);
  }

  &__price {
    @include fluid-fonts(1.125rem, 1.375rem, 120%, 135%);
    font-weight: 500;

    > span {
      font-style: italic;
    }
  }

  &__onrequest {
    @include fluid-fonts(0.8rem, 1rem, 120%, 135%);
    font-weight: 400;
  }

  &__description {
    line-height: 130%;
    margin-top: clamp-it(0.8rem, 1.1rem);
  }

  &__select {
    margin-top: clamp-it(1.4rem, 2rem);

    &__button {
      @include fluid-fonts(0.875rem, 1rem, 100%, 100%);
      border: 1px solid $primary;
      padding: clamp-it(0.46rem, 0.55rem) clamp-it(0.75rem, 1.4rem);
      background-color: transparent;
      display: flex;
      align-items: center;

      > span {
        margin-right: clamp-it(0.85rem, 1.4rem);
      }

      &__radio {
        height: clamp-it(0.85rem, 1rem);
        width: clamp-it(0.85rem, 1rem);
        border-radius: 50%;
        border: 1px solid $primary;
        position: relative;
        display: inline-block;

        &::after {
          position: absolute;
          content: '';
          display: inline-block;
          border-radius: 50%;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          background-color: $primary;
          opacity: 0;
          transition: opacity 0.25s ease-in;
        }

        &--active {
          &::after {
            opacity: 1;
          }
        }
      }

      &--active {
        > div::after {
          opacity: 1;
        }
      }
    }
  }
}

.transfer-selection {
  &__inner {
    &:not(:first-child) {
      .selection-items__transfer {
        padding-top: clamp-it(0.55rem, 0.95rem);
      }
    }
  }
}
