@import '../variables';
@import '../utils/functions';
@import '../base/grid';

$sliderHeight: clamp-it(2.5rem, 5rem);
$thumbsHeight: clamp-it(4.719rem, 4.819rem);
$modalPadding: clamp-it(0.9rem, 1.5rem);
$carouselMargin: clamp-it(4rem, 7rem);
$carouselMarginHalf: clamp-it(2.5rem, 3.75rem);
$carouselMarginThird: clamp-it(3.75rem, 5.625rem);
$buttonDiameter: clamp-it(1.5rem, 3.25rem);
$buttonsMargin: clamp-it(0.6rem, 1.5rem);

.expanded-gallery {
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
  }

  .carousel .carousel-status {
    bottom: 0;
    top: auto;
    right: auto;
    left: 0;
    font-size: 14px;
  }

  &__button {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 0;
    padding: 0;
    display: block;
    transform: translateY(50%) translateY($carouselMarginHalf);
    background-color: transparent;

    svg {
      height: $buttonDiameter;
      width: $buttonDiameter;
    }

    &--previous {
      z-index: 1;
      background: transparent;
      margin-right: calc($buttonDiameter + $buttonsMargin);

      svg {
        circle {
          fill: transparent;
          stroke: $primary-light-green;
          transition: fill 0.25s ease-in;
        }

        path {
          stroke: $primary-light-green;
          fill: none;
          transition: stroke 0.25s ease-in;
        }
      }

      &:hover {
        svg {
          circle {
            fill: $primary-light-green;
          }

          path {
            stroke: $white !important;
          }
        }
      }
    }

    &--next {
      border-radius: 50px;

      svg {
        circle {
          fill: $primary-light-green;
          stroke: $primary-light-green !important;
          transition: fill 0.25s ease-in;
        }

        path {
          stroke: $white !important;
          fill: none;
          transition: stroke 0.25s ease-in;
        }
      }

      &:hover {
        svg {
          circle {
            fill: transparent;
          }

          path {
            stroke: $primary-light-green !important;
          }
        }
      }
    }
  }

  &__label {
    position: absolute;
    top: calc(100% - $thumbsHeight - $carouselMarginThird - 2rem);
  }

  .slider {
    list-style-type: none;
    height: calc(100% - $sliderHeight);

    > .slide {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }

  .carousel {
    &.carousel-slider {
      height: auto;
      margin-bottom: $carouselMargin;
      overflow: visible;

      //@include medium {
      //  max-height: calc(100dvh - $modalPadding - $carouselMargin - 1.5rem);
      //}
    }

    .slide img {
      //@include medium {
      //  max-height: calc(100dvh - $modalPadding - $carouselMargin - 1.5rem);
      //}
    }

    .control-dots {
      width: auto;
    }

    .slider-wrapper {
      margin: 0;
      height: 100%;
    }

    .thumbs-wrapper {
      margin: 0;

      ul.thumbs {
        padding: 0;

        .animated {
          padding: 0;
        }

        .thumb {
          border: 0;
          position: relative;
          padding: 0;

          &.selected,
          &:hover {
            border: 0;
          }

          > img {
            height: auto;
            width: 120px;
          }

          > span {
            &::after {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              background: rgba(0, 0, 0, 0);
              transition: background 0.3s ease-in;
            }
          }

          &:not(.selected) {
            > span::after {
              background: rgba(0, 0, 0, 0.75);
            }
          }
        }
      }

      .control-arrow {
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }

        &.control-next:before {
          border-left: 8px solid $secondary;
        }

        &.control-prev:before {
          border-right: 8px solid $secondary;
        }
      }
    }
  }

  &__thumb {
    display: inline-block;
    position: relative;
    width: 100%;

    > img {
      color: transparent;
      height: initial;
    }
  }
}

.theme-dark-green,
.theme-medium-green {
  .expanded-gallery__button {
    background-color: transparent;

    &--previous {
      svg {
        circle {
          fill: transparent;
          stroke: $secondary;
          transition: fill 0.25s ease-in;
        }

        path {
          stroke: $secondary;
          fill: none;
          transition: stroke 0.25s ease-in;
        }
      }

      &:hover {
        svg {
          circle {
            fill: $secondary;
          }

          path {
            stroke: $primary !important;
          }
        }
      }
    }

    &--next {
      svg {
        circle {
          fill: $secondary;
          stroke: $secondary !important;
          transition: fill 0.25s ease-in;
        }

        path {
          stroke: $primary !important;
          fill: none;
          transition: stroke 0.25s ease-in;
        }
      }

      &:hover {
        svg {
          circle {
            fill: transparent;
          }

          path {
            stroke: $secondary !important;
          }
        }
      }
    }
  }
}
