@import '../utils/mediaQueries';

.mobile-slide {
  width: 100%;
  overflow: hidden;

  &--active {
    overflow: initial;
  }

  &__inner {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    transition: transform 1s ease-in;

    @include xmedium {
      display: block;
      flex-wrap: initial;
    }
  }

  @include xmedium {
    width: initial;
  }

  &__step {
    transition: max-height 1.4s ease-in, z-index 0.5s ease-in;
    min-height: 5px;
    max-height: 5px;
    z-index: 1;

    @include xmedium {
      max-height: initial;
    }

    &--active {
      max-height: 563rem;
      z-index: 2;
    }

    .grid-section {
      &:last-child {
        @include xmediumdown {
          padding-bottom: 0;
        }
      }
    }
  }
}
