@import '../variables';
@import '../utils/functions';

$itemWidth: clamp-it(2.5rem, 3.125rem);
$labelMargin: clamp-it(0.5rem, 1rem);
$mobileContainerHeight: clamp-it(4.5625rem, 5.3125rem);

.progressbar {
  width: 100%;
  position: relative;

  &--desktop {
    @extend .max-width;
    width: 100%;
    position: relative;
    justify-content: space-between;
    display: none;

    @include xlarge {
      display: flex;
    }
  }

  &--mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 42;

    @include xlarge {
      display: none;
    }

    .progressbar {
      &__inner {
        height: $mobileContainerHeight;
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        background-color: $white;
      }

      &__progress {
        height: 5px;
        width: 0;
        background-color: $secondary-accent-green;
        transition: width 1s ease-in;
      }

      &__label--mobile {
        @include fluid-fonts(1.125rem, 1.25rem, 100%, 100%);
        margin-bottom: clamp-it(0.5rem, 0.75rem);
      }

      &__step {
        @include fluid-fonts(0.6875rem, 0.75rem, 100%, 100%);
        color: $gray-500;

        &__back {
          position: absolute;
          left: clamp-it(1rem, 2rem);
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;

          &--disabled {
            cursor: initial;
          }
        }
      }
    }
  }

  &__item {
    font-size: clamp-it(1.2rem, 1.4rem);
    width: clamp-it(2.5rem, 3.125rem);
    height: clamp-it(2.5rem, 3.125rem);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-light-green;
    color: $white;
    z-index: 2;
    position: relative;
    transition: background-color 1s ease-in, color 1s ease-in;

    &--passed,
    &--active {
      background-color: $secondary-accent-green;
      color: $primary;
    }

    &--clickable {
      cursor: pointer;
    }

    &__label {
      color: $primary;
      position: absolute;
      top: calc(100% + $labelMargin);
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      font-size: clamp-it(0.75rem, 0.875rem);
      line-height: 100%;
    }

    &:last-child,
    &:nth-last-child(2) {
      svg {
        font-size: clamp-it(2.5rem, 2.8rem);
      }
    }
  }

  &__line {
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: $primary-light-green;
    top: calc(($itemWidth / 2) - 1px);

    > div {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      height: 100%;
      width: 0;
      background-color: $secondary-accent-green;
      transition: width 1s ease-in;
    }
  }
}
