@import '../variables';
@import './macros';

.btn {
  &.btn-primary {
    background-color: $primary-dark-green;
    display: inline-flex;
    padding: clamp-it(0.4125rem, 0.625rem) clamp-it(1.5125rem, 1.875rem);
    justify-content: center;
    align-items: center;
    color: $white;
    line-height: 190%;
    border: 1px solid $primary-dark-green;
    transition: border 0.3s linear, background-color 0.3s linear;
    box-shadow: none !important;
    border-radius: 0;

    .svg-button-caret path {
      stroke: $white;
    }

    &:focus {
      outline: 2px solid $secondary-accent-green;
    }

    &:hover {
      background-color: transparent;
      color: $primary-dark-green;

      .svg-button-caret path {
        stroke: $primary-dark-green;
      }
    }

    &:disabled,
    &.disabled {
      background-color: $grey;
      border-color: $grey;

      &:focus {
        outline: 0;
        border-color: $grey;
      }

      &:hover {
        background-color: $grey;
        border-color: $grey;
        color: $white;
        cursor: default;

        .svg-button-caret path {
          stroke: $white;
        }
      }
    }

    &.btn-primary--small {
      font-size: 0.6875rem;
      padding: 0.625rem 0.9375rem;
      line-height: 130%;
      height: 2.125rem;
    }

    .svg-button-caret {
      margin-left: 0.62rem;
    }
  }

  &.btn-secondary {
    @extend %albatros-font;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: clamp-it(0.4125rem, 0.625rem);
    color: $primary;
    font-size: 2rem;
    line-height: 140%;
    border: 1px solid transparent;
    transition: border 0.3s linear;
    box-shadow: none !important;
    border-radius: 0 !important;

    > span:first-child {
      border-bottom: 1px solid transparent;
    }

    .svg-button-caret path {
      stroke: $primary;
    }

    > span:first-child {
      transition: border-bottom 0.3s linear;
    }

    &:focus {
      outline: 0.2px solid $primary;

      > span:first-child {
        border-bottom: 1px solid $primary;
      }

      &:hover {
        margin-bottom: 0;
      }
    }

    &:active {
      background-color: transparent !important;
      border-color: transparent !important;
    }

    &:hover {
      > span:first-child {
        border-bottom: 1px solid $primary;
      }
    }

    &:disabled,
    &.disabled {
      color: $grey;

      .svg-button-caret path {
        stroke: $grey;
      }

      > span:first-child {
        border-bottom: 1px solid $grey;
        margin-bottom: -1px;
      }

      &:hover {
        cursor: default;
      }
    }

    &.btn-secondary--medium {
      font-size: 1rem;
      line-height: 190%;
    }

    &.btn-secondary--small {
      font-size: 0.6875rem;
      line-height: 130%;
    }

    .svg-button-caret {
      margin-left: 0.62rem;
    }
  }
}

.button-caret {
  margin-left: clamp-it(0.25rem, 0.5rem);
}
