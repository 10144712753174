@import '../variables';
@import '../utils/functions';

$headerHeight: clamp-it(4.1875rem, 5.125rem);
$searchHeight: clamp-it(2.58rem, 4.12rem);
$badgeFontSize: clamp-it(0.5rem, 0.7rem);

.hero {
  display: flex;
  width: 100%;
  height: 100dvh;
  max-height: 100dvh;
  position: relative;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.75);

  &--lift-content {
    margin-bottom: -12.5rem;

    @include xmediumdown {
      margin-bottom: 0;
    }
  }

  &--small {
    height: 70dvh;

    &.hero--lift-content {
      margin-bottom: -15dvh;

      @include xmediumdown {
        margin-bottom: 0;
      }
    }
  }

  &__headline {
    position: absolute;
    left: 50%;
    top: calc(41% + $headerHeight - $searchHeight);
    transform: translate(-50%, -50%);
    color: $white;
    padding: 0 clamp-it(3rem, 4rem);
    z-index: 1;
    width: 100%;
    max-width: 70rem;
    text-align: center;

    h2 {
      @include fluid-fonts(2.5rem, 3.5rem, 110%, 140%);
    }
  }

  &__head {
    margin-bottom: clamp-it(1rem, 2rem);
    position: relative;
    display: inline-block;
  }

  &__tagline {
    max-width: 40rem;
    margin: 0 auto;
  }

  &__image,
  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__down {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(18% - 6.3125rem);
    cursor: pointer;

    &--hover {
      .circle-caret-down > div > svg path {
        animation: falling 0.6s linear infinite;
      }
    }

    @keyframes falling {
      0% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(0, 32px);
      }
      51% {
        transform: translate(40px, 32px);
      }
      52% {
        transform: translate(40px, -35px);
      }
      53% {
        transform: translate(0px, -35px);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    .circle-caret-down {
      svg:first-child {
        height: clamp-it(2.75rem, 3.75rem);
        width: clamp-it(2.75rem, 3.75rem);
      }

      > div {
        height: clamp-it(2.125rem, 3.125rem);
        width: clamp-it(2.125rem, 3.125rem);
      }

      svg:last-child {
        height: clamp-it(2rem, 3rem);
        width: clamp-it(2rem, 3rem);
      }

      &--pl {
        transform: rotate(-38deg);
        transform-origin: 50% 50%;
      }
    }
  }

  .video__placeholder-image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  .mgnlEditor.mgnlEditorBar {
    width: 100%;
    top: 0;
    left: 0;
    position: absolute !important;
  }

  &__rating {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;

    .rating-star {
      stroke: $white;

      &:not(:last-child) {
        margin-right: clamp-it(0.15rem, 0.3rem);
      }

      &--half {
        path:last-child {
          fill: $white;
        }
      }

      &--full {
        path:first-child {
          fill: $white;
        }

        path:last-child {
          fill: $white;
          stroke: none;
        }
      }
    }
  }
}
