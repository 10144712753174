@import '../utils/functions';
@import '../base/grid';
@import './results';

$listPadding: clamp-it(0.8rem, 1.2rem);
$doubleListPadding: clamp-it(1.6rem, 2.4rem);

.website-search {
  @include set-scrollbar($gray-400, $primary-light-green);
  background-color: $primary-light-blue;
  padding: clamp-it(5rem, 8rem) $section-spacing-x clamp-it(1.8rem, 3.125rem);
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  transition: transform 1s ease-in;
  transform: translateY(0);
  max-height: 100dvh;
  overflow-y: auto;

  &--hidden {
    transform: translateY(-100%);
  }

  &__close {
    @include fluid-fonts(2rem, 2.5rem, 100%, 100%);
    position: absolute;
    top: clamp-it(0.8rem, 1.2rem);
    right: $section-spacing-x;
    cursor: pointer;
  }

  &__input {
    position: relative;

    input {
      background-color: transparent;
      border-bottom: 1px solid $gray-900;
      padding: clamp-it(0.5rem, 0.8rem) 0 clamp-it(0.5rem, 0.8rem) clamp-it(1.6rem, 2.5rem);

      &:active,
      &:focus {
        border-bottom-color: $black;
        background-color: transparent;
      }
    }

    &__magnify {
      @include fluid-fonts(1rem, 1.25rem, 100%, 100%);
      position: absolute;
      left: clamp-it(0.35rem, 0.65rem);
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }

    &__reset {
      @include fluid-fonts(1rem, 1.25rem, 100%, 100%);
      position: absolute;
      right: clamp-it(0.35rem, 0.65rem);
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &__results {
    margin-top: clamp-it(2.2rem, 3.5rem);

    &__spinner {
      display: flex;
      justify-content: center;

      .custom-spinner {
        width: clamp-it(3.5rem, 6.3rem);
        height: clamp-it(3.5rem, 6.3rem);

        &::after {
          background-color: $primary-light-blue;
          width: clamp-it(2.9rem, 4.6rem);
          height: clamp-it(2.9rem, 4.6rem);
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;

      @include large {
        flex-direction: row;
      }
    }

    &__result {
      &:not(:last-child) {
        padding-bottom: clamp-it(2rem, 3rem);
        margin-bottom: clamp-it(2rem, 3rem);
        border-bottom: 1px solid $gray-400;
      }

      @include large {
        flex: 1 1 25%;
        max-width: 25%;

        &:not(:last-child) {
          padding-right: clamp-it(0.6rem, 0.95rem);
          margin-right: clamp-it(0.6rem, 0.95rem);
          border-right: 1px solid $gray-400;
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: 0;
        }

        &:not(:first-child) {
          padding-left: clamp-it(0.6rem, 0.95rem);
        }
      }
    }

    &__headline {
      font-weight: 600;
      margin-bottom: clamp-it(1.15rem, 1.65rem);
    }

    &__link {
      line-height: 150%;

      &:not(:last-of-type) {
        margin-bottom: clamp-it(0.7rem, 0.95rem);
      }

      @include large {
        &:not(:last-of-type) {
          padding-bottom: clamp-it(0.45rem, 0.75rem);
          margin-bottom: clamp-it(0.45rem, 0.75rem);
          border-bottom: 1px solid $gray-400;
        }
      }

      &__to-all {
        margin-top: clamp-it(1.25rem, 1.8rem);
        display: block;

        > a {
          @include fluid-fonts(0.8rem, 0.875rem, 100%, 100%);
          padding: 0;
          text-decoration: underline;

          &:hover {
            > span:first-child {
              border-bottom: 0;
              text-decoration: underline;
            }
          }

          > span:first-child {
            border-bottom: 0 !important;
            transition: none;
          }

          &:focus,
          &:active {
            outline: 0;
          }

          > svg {
            @include fluid-fonts(0.65rem, 0.78rem, 100%, 100%);
          }
        }
      }
    }
  }
}

.website-search-full {
  position: relative;

  &__page {
    padding-top: clamp-it(4.6rem, 16rem);
    background-color: $primary-light-blue;

    > .grid-section {
      padding-top: 0 !important;
      padding-bottom: clamp-it(6rem, 8rem);
    }
  }

  &__search {
    display: flex;
    flex-direction: column;
  }

  &__results {
    display: flex;
    flex-wrap: nowrap;
    width: 400%;
    overflow: hidden;
    transition: transform 1s ease-in;

    a.results__list__item {
      text-decoration: none;
    }

    > div {
      width: 25%;
    }

    &__container {
      overflow: hidden;
      position: relative;
      margin-top: clamp-it(2rem, 4rem);
      width: calc(100% + $doubleListPadding);
      left: calc($listPadding * -1);
    }

    &__list {
      padding: 0 $listPadding;

      &--teasers {
        display: flex;
        flex-wrap: wrap;
        gap: clamp-it(0.94rem, 1.34rem) clamp-it(0.84rem, 1.24rem);

        > a {
          margin-top: clamp-it(2rem, 2.5rem);
          flex: 1 1 100%;
          max-width: 100%;

          @include medium {
            flex: 1 1 calc(50% - ($contentGap / 2));
            max-width: calc(50% - ($contentGap / 2));
          }

          @include large {
            flex: 1 1 calc((100% / 3) - ($contentGap * 2 / 3));
            max-width: calc((100% / 3) - ($contentGap * 2 / 3));
          }

          @include xlarge {
            flex: 1 1 calc(25% - ($contentGap * 3 / 4));
            max-width: calc(25% - ($contentGap * 3 / 4));
          }
        }
      }

      &--row {
        background-color: $white;
        padding: clamp-it(1.2rem, 2rem);
        margin-bottom: clamp-it(1.2rem, 2rem);
        transition: max-height 1s ease-in;
        max-height: 600rem;
        overflow: auto;

        display: flex;
        flex-direction: column;

        @include xmedium {
          display: block;
          flex-direction: row;
        }

        a {
          order: 1;

          @include xmedium {
            float: right;
            order: 0;
          }

          > span {
            margin-right: clamp-it(0.45rem, 0.7rem);
          }
        }

        > p {
          margin-top: 1rem;
        }
      }

      > h4 {
        text-align: center;
        text-transform: uppercase;
        margin-bottom: clamp-it(2rem, 4rem);
        margin-top: clamp-it(2rem, 4rem);
      }
    }
  }

  &__type {
    order: 2;

    @include xmedium {
      order: initial;
    }

    &--desktop {
      position: relative;
      display: flex;
      justify-content: center;

      > div {
        max-width: 57.5rem;
        width: 100%;
        display: flex;

        > div {
          flex: 1 1 25%;
          max-width: 25%;
          text-align: center;
          padding: clamp-it(0.5rem, 0.75rem) 0;
          background-color: $white;
          transition: background-color 0.25s ease-in, color 0.25s ease-in;
          cursor: pointer;

          &:not(:last-child) {
            border-right: 1px solid $gray-400;
          }
        }
      }

      &__selected {
        background-color: $primary !important;
        color: $white;
      }
    }

    &--mobile {
      .select-field {
        width: 100%;

        &__input {
          border: 0;
          box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.3);
          padding: clamp-it(0.68rem, 0.9rem) clamp-it(0.95rem, 1.2rem);
        }

        &__options {
          max-height: 15rem;
        }

        &__option > div {
          padding-top: clamp-it(0.55rem, 0.75rem);
          padding-bottom: clamp-it(0.52rem, 0.72rem);
        }
      }
    }
  }

  &__input {
    display: flex;
    justify-content: center;
    margin-bottom: clamp-it(3.3rem, 5rem);

    @include xmedium {
      margin-top: clamp-it(3rem, 4.2rem);
      margin-bottom: 0;
    }

    &__inner {
      position: relative;
      width: 100%;

      @include xmedium {
        max-width: 19rem;
      }

      > label {
        @include fluid-fonts(0.685rem, 0.75rem, 100%, 100%);
        font-weight: 500;
        display: none;

        @include xmedium {
          display: inline-block;
        }

        + div {
          position: relative;

          svg {
            @include fluid-fonts(1rem, 1.25rem, 100%, 100%);
            position: absolute;
            left: clamp-it(0.25rem, 0.45rem);
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }
        }
      }

      input {
        background-color: transparent;
        border-bottom: 1px solid $gray-900;
        padding: clamp-it(0.5rem, 0.8rem) 0 clamp-it(0.5rem, 0.8rem) clamp-it(1.6rem, 2.5rem);

        &:active,
        &:focus {
          border-bottom-color: $black;
          background-color: transparent;
        }
      }
    }
  }
}
