@use "sass:math";
@use "sass:map";
@use "sass:string";
@import '../variables';
@import '../utils/mediaQueries';

@function convert-to-number($value) {
  @if type-of($value) == 'number' {
    @return $value;
  } @else if type-of($value) != 'string' {
    @error 'no string!';
  }

  $result: 0;
  $digits: 0;
  $minus: str-slice($value, 1, 1) == '-';
  $numbers: (
    '0': 0,
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9
  );

  @for $i from if($minus, 2, 1) through str-length($value) {
    $character: str-slice($value, $i, $i);

    @if (index(map-keys($numbers), $character) or $character == '.') {
      @if $character == '.' {
        $digits: 1;
      } @else if $digits == 0 {
        $result: $result * 10 + map-get($numbers, $character);
      } @else {
        $digits: $digits * 10;
        $result: $result + map-get($numbers, $character) / $digits;
      }
    }
  }

  @return if($minus, -$result, $result);
}

@function convert-to-unit($value) {
  @if type-of($value) != 'string' {
    @error 'no string!';
  }

  $units: (
    'px': 1px,
    'cm': 1cm,
    'mm': 1mm,
    '%': 1%,
    'ch': 1ch,
    'pc': 1pc,
    'in': 1in,
    'em': 1em,
    'rem': 1rem,
    'pt': 1pt,
    'ex': 1ex,
    'vw': 1vw,
    'vh': 1vh,
    'vmin': 1vmin,
    'vmax': 1vmax
  );
  $parsed-unit: false;

  @each $unit in $units {
    @if (str-index($value, nth($unit, 1))) {
      $parsed-unit: nth($unit, 2);
    }
  }

  @if (not $parsed-unit) {
    @error 'not a valid unit!';
  }

  $stringValue: str-slice($stringValue, str-index($stringValue, nth($unit, 1)));
  @return #{string.unquote($stringValue)}#{$parsed-unit};
}

@function round-decimals($number, $places: 0) {
  $n: 1;
  @if $places > 0 {
    @for $i from 1 through $places {
      $n: $n * 10;
    }
  }
  @return math.div(math.round($number * $n), $n);
}

@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

@function convert-to-rem($px) {
  $rem: math.div($px, $base-font-size) * 1rem;
  @return $rem;
}

@function convert-to-pixel($rem) {
  @if (type-of($rem) == 'number') and ($rem != 0) {
    $unit: unit($rem);
    $val: parseInt($rem);

    @if ($unit == 'rem') {
      @return ($val * $base-font-size-value) * 1px;
    } @else {
      @return $rem;
    }
  } @else {
    @return $rem;
  }
}

@function clamp-it(
  $min-px,
  $max-px,
  $min-breakpoint: $default-min-breakpoint,
  $max-breakpoint: $default-max-breakpoint,
  $round-to: 3
) {
  $minpx: convert-to-pixel($min-px);
  $maxpx: convert-to-pixel($max-px);
  $slope: math.div($maxpx - $minpx, $max-breakpoint - $min-breakpoint);
  $slope-vw: round-decimals($slope * 100, $round-to);
  $intercept-rems: round-decimals(convert-to-rem($minpx - $slope * $min-breakpoint), 2);
  $min-rems: round-decimals(convert-to-rem($minpx), $round-to);
  $max-rems: round-decimals(convert-to-rem($maxpx), $round-to);
  @return clamp(#{$min-rems}, #{$slope-vw}vw + #{$intercept-rems}, #{$max-rems});
}

@mixin fluid-fonts(
  $min-px,
  $max-px,
  $min-line-height: $default-line-height,
  $max-line-height: $default-line-height,
  $min-breakpoint: $default-min-breakpoint,
  $max-breakpoint: $default-max-breakpoint,
  $center-breakpoint: $default-center-breakpoint
) {
  font-size: clamp-it($min-px, $max-px, $min-breakpoint, $max-breakpoint);
  line-height: $min-line-height;

  @include break($center-breakpoint) {
    line-height: $max-line-height;
  }
}

@mixin set-grid-columns($columns, $offset) {
  @if $offset != 0 {
    @if $columns == 0 {
      display: none;
    } @else {
      grid-column-start: $offset;
      grid-column-end: span $columns;
    }
  } @else {
    @if $columns == 0 {
      display: none;
    } @else {
      grid-column: span $columns;
    }
  }
}

@mixin set-scrollbar($scrollbarColor, $thumbColor, $width: 5px, $height: 8px, $borderRadius: 4px) {
  scrollbar-color: $thumbColor $scrollbarColor;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    border-radius: $borderRadius;
    background-color: $scrollbarColor;
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $borderRadius;
    background-color: $thumbColor;
  }

  &::-webkit-scrollbar-track {
    border-radius: $borderRadius;
    background-color: $scrollbarColor;
  }
}
