@import '../utils/functions';
@import '../base/macros';

.itinerary {
  &__wrapper {
    @include medium {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    margin-bottom: clamp-it(2.5rem, 3.5rem);
  }

  &__header {
    @include fluid-fonts(1.5rem, 3.5rem, 150%, 140%);
    font-weight: 500;
  }

  &__btn {
    height: clamp-it(3rem, 3rem);
    margin-top: clamp-it(1.25rem, 2rem);

    @include medium {
      margin-top: initial;
    }

    &__text {
      @include fluid-fonts(0.8rem, 1rem, 180%, 190%);
      font-family: var(--dm-sans-font), Arial, Helvetica, sans-serif !important;
      margin-right: clamp-it(0.8rem, 1.2rem);
    }

    &__spinner {
      height: 1.5rem;
      width: 1.5rem;
      position: relative;
      top: 0.2rem;

      > * {
        display: none;
      }
    }

    svg path {
      stroke: currentColor !important;
    }
  }

  &__accordion {
    &__group {
      margin-bottom: clamp-it(0.8rem, 1.5rem);

      &__text {
        @include medium {
          margin-top: initial;
        }
        margin-top: clamp-it(0.8rem, 1.5rem);
        p {
          &:not(:last-child) {
            margin-bottom: clamp-it(1rem, 1.5rem);
          }
        }

        &__wrapper {
          &--desktop {
            display: none !important;

            @include medium {
              display: grid !important;
            }
          }

          &--mobile {
            display: block;

            @include medium {
              display: none;
            }
          }
        }
      }

      &__days {
        margin-left: clamp-it(1.5rem, 2.5rem);
        white-space: nowrap;
      }
      &__imageslider {
        width: 100%;
        @include large {
          $widthSubtraction: clamp-it(1.5rem, 4.5rem);
          width: calc(50% - $widthSubtraction);
          float: left;
          margin-right: clamp-it(1.5rem, 2.5rem);
        }
      }
    }

    &__header {
      &__text {
        @include fluid-fonts(1.125rem, 1.375rem, 140%, 170%);
        margin-bottom: 0;
      }

      &__days {
        margin-bottom: 0;

        &__wrapper {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
