@import '../variables';
@import '../utils/functions';
@import '../base/macros';

$containerPadding: clamp-it(1.5rem, 10rem);
$contentPadding: clamp-it(0.55rem, 0.9rem);
$paddingDifference: clamp-it(0rem, 1.5rem);
$searchBarHeight: clamp-it(3.35912rem, 4.75rem);
$mobileInputHeight: clamp-it(3rem, 4rem);
$searchSubmitHeight: clamp-it(2.8125rem, 3.5rem);
$searchSubmitMobileContainerVerticalPadding: clamp-it(1.75rem, 2.45rem);

.mobile-overlay--search {
  .mobile-overlay__content {
    height: 100%;
  }
}

.search {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  position: relative;
  width: 100%;
  box-shadow: 0px -10px 40px 0px rgba(0, 0, 0, 0.25);

  .collapsing,
  .collapse {
    width: 100%;
    background-color: $white;
  }

  &__spinner {
    left: 50%;
    bottom: 18%;
    position: absolute;
    transform: translateX(-50%);
  }

  &__custom-spinner {
    .custom-spinner {
      width: clamp-it(3.5rem, 6.3rem);
      height: clamp-it(3.5rem, 6.3rem);

      &::after {
        background-color: $primary-light-blue;
        width: clamp-it(2.9rem, 4.6rem);
        height: clamp-it(2.9rem, 4.6rem);
      }
    }

    &.search-for-results,
    &.search__container {
      display: flex;
      justify-content: center;
    }
  }

  &__content {
    width: 100%;
    min-height: 1rem;
    padding: $contentPadding;
    display: flex;

    input {
      border-bottom: 0;
      display: block;
      padding-bottom: 0;
      width: 100%;
    }

    &__hr {
      margin: clamp-it(1rem, 1.55rem);
      color: $gray-300;

      + .search__content--checkboxes-expanded {
        padding-top: 0;
      }
    }

    &--checkboxes {
      display: block;
      column-count: 3;

      @include xlarge {
        column-count: 4;
      }

      > div {
        break-inside: avoid-column;
        padding: clamp-it(0.33rem, 0.46rem) 0;
      }

      &-expanded {
        @include large {
          column-count: 4;
        }

        @include xlarge {
          column-count: 5;
        }
      }
    }

    &__continents {
      @extend %no-scrollbar;
      padding: $contentPadding $contentPadding clamp-it(0.3rem, 0.95rem);
      position: relative;
      display: flex;
      overflow: auto;
      flex-wrap: nowrap;
      cursor: grab;
      user-select: initial;
      flex-grow: 1;

      &--grab {
        cursor: grabbing;
        user-select: none;

        > div {
          pointer-events: none;
        }
      }

      &--full-search {
        padding-bottom: 0;
      }
    }

    &__continent {
      @include fluid-fonts(0.6rem, 0.875rem, 115%, 120%);
      position: relative;
      cursor: pointer;
      padding: clamp-it(0.25rem, 0.31rem) clamp-it(1rem, 1.25rem);
      transition: background-color 0.25s ease-in;

      &:not(:last-child):not(.search__content__continent--active):not(:hover) {
        &::after {
          content: '';
          display: block;
          height: 100%;
          width: 1px;
          background-color: $gray-900;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      &:hover,
      &--active {
        background-color: $primary-light-blue;
      }
    }

    &__container {
      width: 100%;
      position: relative;
      display: flex;
    }

    &__input {
      position: relative;
      flex: 1 1 auto;

      &__dropdown {
        position: absolute;
        min-width: 17.5rem;
        top: 100%;
        left: 0;
        padding: 2.31rem 1.63rem 1.2rem;
        opacity: 1;
        transition: opacity 0.3s ease-in;
        background-color: $white;
        z-index: 10;

        &--hidden {
          opacity: 0;
        }

        &__headline {
          @include fluid-fonts(0.7rem, 0.875rem, 110%, 110%);
          display: flex;
          align-items: center;
          text-transform: uppercase;
          margin-bottom: 0.62rem;

          > svg {
            @include fluid-fonts(1.05rem, 1.15rem, 100%, 100%);
            margin-right: clamp-it(0.3rem, 0.42rem);
          }
        }

        &__item {
          @include fluid-fonts(1rem, 1.125rem, 180%, 180%);
          cursor: pointer;

          &:hover {
            font-weight: 600;
            text-decoration: underline;
          }

          &:not(:last-child) {
            margin-bottom: clamp-it(0.5rem, 0.68rem);
          }
        }

        &__hr {
          color: $primary-light-green;
          margin: clamp-it(1.1rem, 1.56rem) 0;
        }
      }
    }

    &__spinner {
      position: absolute;
      right: 5rem;
      top: 50%;
      transform: translateY(-50%);

      > svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }

    &__mobile {
      padding-bottom: calc($searchSubmitHeight + $searchSubmitMobileContainerVerticalPadding * 2);
      margin-top: clamp-it(1.5rem, 2.5rem);

      h2 {
        margin-bottom: clamp-it(2rem, 3.5rem);
      }

      &__input {
        input {
          width: 100%;
          border-bottom: 0;
          background-color: $gray-400;
          height: $mobileInputHeight;
          padding: 0 0 0 clamp-it(0.75rem, 1.25rem);
          color: $primary;

          &::placeholder {
            color: $gray-700;
          }
        }

        .search__content {
          &__input__dropdown {
            position: relative;
            min-width: initial;
            width: 100%;
            top: initial;
            left: initial;
            padding: 0 clamp-it(1.25rem, 1.8rem);
            opacity: 1;
            transition: initial;
            margin-top: clamp-it(2rem, 2.65rem);
          }

          &__spinner {
            right: clamp-it(1rem, 1.5rem);
          }
        }
      }

      .search__submit {
        display: flex;
        border: 0;
        position: relative;

        &__mobile-container {
          position: fixed;
          width: 100%;
          bottom: 0;
          left: 0;
          padding: $searchSubmitMobileContainerVerticalPadding clamp-it(2rem, 2.7rem);
          box-shadow: 0 2px 8px -3px $black;
          opacity: 1;
          transition: opacity 0.3s ease-in;
          background-color: $white;
          z-index: 2000;

          &--hidden {
            opacity: 0;
          }
        }
      }

      &--slide {
        position: relative;
        overflow: hidden;
        width: 100%;
      }

      &__inner {
        position: relative;
        display: flex;
        width: 200%;
        transition: transform 0.3s ease-in-out;
        transform: translateX(0);
        flex-wrap: nowrap;

        &--transformed {
          transform: translateX(-50%);
        }

        > div {
          width: 50%;
        }
      }

      &__element {
        display: block;
        opacity: 1;
        transition: opacity 0.3s ease-in;

        &--hidden {
          opacity: 0;
        }
      }

      .radio-results {
        width: 100%;

        .checkbox {
          width: 100%;
        }

        &--nav-item {
          .checkbox {
            &__field {
              border: 0;
            }
          }

          svg {
            color: $primary;
          }
        }
      }

      &__back {
        background-color: $primary;
        color: $white;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0;
        margin-bottom: clamp-it(0.5rem, 0.85rem);
        padding: clamp-it(1.3rem, 1.8rem) clamp-it(1.2rem, 1.8rem);
        width: 100%;

        > div {
          display: flex;
          align-items: center;

          > svg {
            margin-right: clamp-it(0.65rem, 0.85rem);
          }
        }

        > svg {
          color: $white;
          transform: rotate(180deg);
        }
      }
    }
  }

  &__container {
    padding: 0 $containerPadding;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 18%;
    max-width: calc(68.5rem + $containerPadding);
    width: 100%;
    z-index: 20;
    text-shadow: none;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    z-index: 2;

    > div {
      flex: 1 1 50%;
      height: $searchBarHeight;
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &:nth-child(2) {
        box-shadow: 1px 0 0 0 $primary inset;
      }

      &:nth-child(3) {
        box-shadow: 0 1px 0 0 $primary inset;
      }

      &:last-child {
        box-shadow: 1px 1px 0 0 $primary inset;
      }

      @include large {
        flex: 1 1 25%;
        box-shadow: none;

        &:not(:first-child) {
          box-shadow: -1px 0 0 0 $primary;
        }
      }

      svg {
        position: relative;
        display: inline-block;
        top: -1px;
        margin-right: clamp-it(0.5rem, 0.875rem);
        font-size: clamp-it(0.8rem, 1.15rem);
      }

      &.search__buttons__selection-state {
        background-color: $primary-light-blue;
      }
    }
  }

  &__submit {
    @include fluid-fonts(0.875rem, 1rem, 100%, 100%);
    background-color: $secondary-accent-green;
    color: $black;
    cursor: pointer;
    display: none;
    height: $searchSubmitHeight;
    padding: 0.15rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;

    @include medium {
      @include fluid-fonts(0.65rem, 0.75rem, 100%, 100%);
      display: flex;
      height: $searchBarHeight;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(0);
      transition: transform 0.3s ease-in;
      max-width: calc($containerPadding - $paddingDifference);
    }

    &__loading {
      position: absolute;
      display: none;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);

      @include medium {
        display: block;
      }

      > svg {
        height: 1.2rem;
        width: 1.2rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &--active {
      transform: translateX(100%);
    }

    span {
      font-weight: 700;
    }

    svg {
      margin-left: clamp-it(0.4rem, 0.65rem);

      @include large {
        margin-left: clamp-it(0rem, 0.35rem);
      }

      path {
        fill: transparent;
        stroke: $primary;
      }
    }
  }

  &__content {
    &__submit {
      svg {
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }
}

.mobile-overlay--search.mobile-overlay {
  position: fixed;
  top: 0;
  background-color: $white;

  .mobile-overlay {
    &__header {
      justify-content: space-between;
    }

    &__menu-icon {
      background-color: $white;
    }

    &__content {
      padding-top: 0.5rem;

      .month-selector {
        margin-top: clamp-it(1.5rem, 2.5rem);
      }
    }
  }
}

.radio-results.radio-results {
  &--nav-item {
    .checkbox {
      &__field {
        border: 0;

        > svg {
          color: $primary;
        }
      }
    }
  }
}
